import React from "react";

const Introduction = () => {
  return (
    <header className="mobile-app valign">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="caption">
            <h1 className="mb-20">
            FREE - 6 Week yoga challenge
              </h1>
              <p>
              Consistency is the biggest challenge. Let’s build a strong 20 minutes morning (6am - 6days) routine together without stress of hard & fancy poses. 
              </p>
              <p>Stay tuned & contact us to reserve a spot for the next session starting from <b>June 24th,2024.</b>
              </p>
              <div className="butons mt-40">
                <a href="#call-to-action" className="butn-gr rounded buton">
                  <span>Contact to Enroll</span>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-5 offset-lg-1">
            <div className="img">
              <img src="/img/intro-vib.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Introduction;

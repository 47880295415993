import React from 'react'
import Split from '../Split'

const CallToAction = ({img}) => {
  return (
    <section
      className="call-action section-padding bg-img"
      style={{backgroundImage: `url(${img ? img : "/img/patrn.svg"})`}}
      id="call-to-action">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-lg-5">
            <div className="content sm-mb30">
              <Split>
                <h6 className="wow words chars splitting" data-splitting>
                  Contact Us to
                </h6>
                <h2 className="wow words chars splitting" data-splitting>
                  Join the <br /> <b className="back-color">Challenge</b>
                </h2>
              </Split>
            </div>
          </div>

          <div className="col-md-4 col-lg-4 valign ">
            <h4 class=" color-font"><a href="mailto:info@aumashram.org,aumdallas@gmail.com" target="_blank">
              Email: aumdallas@gmail.com, info@aumashram.org
            </a></h4>
          </div>
          <div className="col-md-3 col-lg-3 valign ">
            <h4 class=" color-font">
              Call/WhatsApp: <span>+1 - 989.383.0030</span>
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallToAction
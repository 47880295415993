import React from "react";
import {Link} from 'gatsby'

const OtherOfferings = () => {
  return (
    <section className="blog-list section-padding ">
      <div className="container">
        <div className="row">

          <div className="col-lg-7 offset-lg-1">

            <div className="item wow fadeInUp" data-wow-delay=".3s">
              <div className="img valign wow-circle">
                <img src="/img/sopanam.jpg" alt="sopanam" />
              </div>
              <div className="cont valign">
                <div>
                  <div className="info">
                    <Link to="https://www.aumashram.org/aum-yog-sadhana/aum-yog-sadhana-dallas/" className="tag">
                      <span>Enroll</span>
                    </Link>
                  </div>
                  <h5>
                    <Link to="http://yogsopanam.org/">
                      Yoga Sopanam for Kids - 7 Level Structured program.
                    </Link>
                  </h5>
                </div>
              </div>
            </div>

            <div className="item wow fadeInUp" data-wow-delay=".3s">
              <div className="img valign">
                <img src="/img/margam.jpg" alt="" />
              </div>
              <div className="cont valign">
                <div>
                  <div className="info">
                    <Link to="https://www.aumashram.org/aum-yog-sadhana/aum-yog-sadhana-dallas/" className="tag">
                      <span>Enroll</span>
                    </Link>
                  </div>
                  <h5>
                    <Link to="https://www.aumashram.org/aum-yog-sadhana/aum-yog-sadhana-dallas/">
                      Yoga Margam for Astanga Vinyasa Yoga.
                    </Link>
                  </h5>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-4">
            <div className="head md-mb50">
              <h6 className="back-color">Other Events</h6>
              <h3>Here are our ongoing and upcomming events.</h3>
              <p>
                Serving community by spreading Yoga.
              </p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default OtherOfferings;

import React from "react";
import Introduction from "components/Intro/intro";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import Footer from "components/Footer/footer";
import ScrollToTop from "components/scrollToTop";
import Cursor from "components/cursor";
import {Script} from "gatsby";
import HowItWorks from "components/HowItWorks/how-it-works";
import CallToAction from "components/Call-to-action/call-to-action";
import MinimalArea2 from "components/Minimal-Area2/minimal-area2";
import FreelancreIntro from "components/Freelancre-intro/freelancre-intro";
import OtherOfferings from "components/OtherOfferrings/other-offerings";

const Home = () => {

  return (
    <>
    <Cursor />
      <Introduction />
      <HowItWorks />
       <MinimalArea2 />
       <OtherOfferings/>
       {/*<FreelancreIntro />*/}
      <FullTestimonials />
      <CallToAction/>
      <Footer />
      <ScrollToTop />
      <Script src="/js/wowInit.js"></Script>
    </>  );
};

export const Head = () => {
  return (
    <>
      <title>6AM - Yoga for 6 Days a Week</title>
      <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap" rel="stylesheet" />
    </>
  )
}

export default Home;

import React from "react";
import Split from "../Split";
import { Link } from 'gatsby';
import { thumparallaxDown } from "../../common/thumparallax";

const MinimalArea2 = () => {
  React.useEffect(() => {
    setTimeout(() => {
      thumparallaxDown();
    }, 1000);
  }, []);
  return (
    <section className="min-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="img">
              <img
                className="thumparallax-down"
                src="/img/aum-dallas.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 valign">
            <div className="content">
              <Link to={`https://www.aumashram.org/aum-yog-sadhana/aum-yog-sadhana-dallas/`}>
              <h4 className="color-font">AUM Yog Sadhana - Dallas Presents.</h4>
              </Link>
              <Split>
                <p className="wow txt words chars splitting" data-splitting>
                Yoga classes for everyone. Come join us for authentic lineage based yoga practice.
                </p>
              </Split>
              <ul>
                <li className="wow fadeInUp" data-wow-delay=".2s">
                We are deeply humbled and gives immense joy to share the ancient timeless wisdom of Yoga which has a potential to transform us from within and experience our true self that is “Sat Chit Ananda.
                </li>
                <li className="wow fadeInUp" data-wow-delay=".4s">
                We offer Ashtanga Yoga system that is a living lineage dates back nearly five thousand years in an unbroken line of teachers, sages and gurus.
                </li>
              </ul>
              <Link to={`https://www.aumashram.org/aum-yog-sadhana/aum-yog-sadhana-dallas/`} className="butn bord curve mt-40 wow fadeInUp" data-wow-delay=".8s">
                <span>Discover aumashram.org</span>
              </Link>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MinimalArea2;

/* eslint-disable react/jsx-no-target-blank */
import React from "react";

const Footer = ({hideBGCOLOR}) => {
  return (
    <footer className={`${!hideBGCOLOR ? "sub-bg" : ""}`} id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title">
                <h5>We are</h5>
              </div>
              <ul>
                <li>
                  <div className="cont">
                    <span> Aum Dallas </span>
                  </div>
                </li>
                <li>
                  <div className="cont">
                    <a href="https://www.aumashram.org" target="_blank">
                      In association with aumashram.org
                    </a>
                  </div>
                </li>

              </ul>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title">
                <h5>Non Profit</h5>
              </div>
              <ul>
                <li>
                  <div className="cont">
                    <h6>Aum Education Society of Texas | 2021 - 2024 | EIN 82-1659144</h6>
                  </div>
                </li>
                <li>
                  <div className="cont">
                    <h6><i className="fa fa-copyright"></i> 2024 All rights reserved.</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="item">
              <div className="title">
                <h5>Follow Us</h5>
              </div>
              <div className="social">
                <a href="https://www.instagram.com/aumdallas/" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/profile.php?id=100083054381517" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/gsreddy.ashtanga/" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com/@aumashram" target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>



        </div>
      </div>
    </footer>
  );
};

export default Footer;

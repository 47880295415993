import React, { useEffect } from "react";
import teamSkillsProgress from "common/teamSkillsProgress";
import tooltipEffect from "common/tooltipEffect";
import whyAndHowData from "data/why-and-how.json";

const HowItWorks = () => {
  useEffect(() => {
    teamSkillsProgress();

    window.addEventListener('load', () => {
      setTimeout(() => {
        tooltipEffect()
      }, 0);
    })
  }, []);
  
  return (
    <section
        className="call-action section-padding bg-img"
        style={{ backgroundImage: `url(${ "/img/patrn.svg"})` }}
      >
    <div className="team-crv section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="content wow fadeInUp md-mb30" data-wow-delay=".5s">
              <div className="sub-title">
                <h6>Why The Challenge</h6>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <h3 className="co-tit mb-15">
                6 Reasons why it works
              </h3>
              <div className="skills-box mt-40">
                {whyAndHowData.why.map((w) => (
                  <div className="skill-item" key={w.id}>
                    <h6 className="custom-font">{w.text}</h6>
                    <div className="skill-progress">
                      <div className="progres" data-value={w.value}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-6 valign">
            <div className="content wow fadeInUp md-mb30" data-wow-delay=".5s">
              <div className="sub-title">
                <h6>How It Works</h6>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <h3 className="co-tit mb-15">
                6 Reasons how it works
              </h3>
              <div className="skills-box mt-40">
                {whyAndHowData.how.map((h) => (
                  <div className="skill-item" key={h.id}>
                    <h6 className="custom-font">{h.text}</h6>
                    <div className="skill-progress">
                      <div className="progres" data-value={h.value}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    </section>
  );
};

export default HowItWorks;
